import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path:'auth',
    loadChildren:() => import('./Modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate:[LoginGuard] 
  },
  {
    path:'admin',
    loadChildren:() => import('./Modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate:[AuthGuard]
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
